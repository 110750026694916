<template>
    <el-select :placeholder="placeholder" v-model="bind" filterable remote reserve-keyword :remote-method="LoadDatas" :loading="loading" :multiple="false" :clearable="clearable">
        <el-option v-for="(item,index) in pageData" :key="index" :label="item[label]" :value="item[lakey]" />
    </el-select>
</template>

<script>
export default {
    name: "select-search",
    model: {
        prop: 'value',
        event: 'change'
    },
    props: {
        api: {type: String,default: ''},
        lakey: {type: String,default:''},
        label: {type: String,default:''},
        value: null,
        search: {type: Object,default: ()=>{return {}}},
        clearable: {type: Boolean,default:false},
        placeholder: {type: String,default:'请输入关键词'}
       
    },
    data() {
        return {
            bind:'',
            loading:false,
            pageData:[],
            pageInfo:{
                _page:1,
                _size:20,
            },
        };
    },
    created() {
        if( this.value ){
            this.bind = this.value,this.loading = true,this.search[this.lakey] = this.bind;
            this.$get(this.api,Object.assign(this.search,this.pageInfo)).then(res=>{
                this.loading = false,this.search[this.lakey]=null,this.pageData = res.data,this.pageInfo._page = res.data.length < res.data.extra.pageInfo.size ?0:(this.pageInfo._page+1);
            }).catch(err=>{
                this.loading = false,this.search[this.lakey]=null,this.pageInfo._page = 0;
            })
        }
        this.LoadDatas(this.value);
    },
    methods: {
       LoadDatas(query){
            if( !this.loading ){
                this.loading = true;this.search[this.label] = query,this.pageInfo._page = 1;
                this.$get(this.api,Object.assign(this.search,this.pageInfo)).then(res=>{
                    this.loading = false,this.pageData = res.data,this.pageInfo._page = res.data.length < res.data.extra.pageInfo.size ?0:(this.pageInfo._page+1);
                }).catch(err=>{
                    this.loading = false,this.pageInfo._page = 0;
                })
            }
        }
    },
    watch: {
        value(value) {
            this.bind = this.value;
        },
        bind(vals) {
            this.$emit('change',vals);
        }
    }
};
</script>